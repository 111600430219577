<template>
    <div class="container">


        <div class="mt-4 mb-4">
            <h4 class="text-center">Дисциплины рабочих учебных планов</h4>
            <!--            <p class="text-center">-->
            <!--                Кафедра {{departments.length ? departments.find(i=>i.id==departmentID).name : getDepartmentId}}-->
            <!--            </p>-->

            <div class="col-md-4 m-3">
                <div>Фильтрация по кафедрам</div>
                <select class="form-control form-select" id="filterFilterDepartments"
                        @change="changeFilterDepartments">
                    <option v-for="(item, index) in departments"
                            :value="item.id"
                            :key="index"
                            :selected="item.id==departmentID">{{ item.name_ru }}
                    </option>
                </select>
            </div>

            <DataTable :value="curriculumDisciplinesData.curriculumDisciplines">
                <Column field="code" header="Код"></Column>

                <Column header="Название">
                    <template #body="{data}">
                        <router-link
                                :to="+data.is_selectable === 1 ? `/bind-selectable-groups?formation_education_program_id=${data.formation_education_program_id}&education_curriculum_id=${data.education_curriculum_id}` : `/binding-to-curriculum?discipline_id=${data.discipline_id}&semester=${data.semester}`">
                            {{data.name}}
                        </router-link>
                    </template>
                </Column>
                <Column field="native_name" header="Язык"></Column>
                <Column field="semester" header="Семестер"></Column>
                <Column field="study_course" header="Курс"></Column>
            </DataTable>

            <!--                        <DataTable :value="teachingLoad_form.curriculums" :paginator="true" :rows="10"-->
            <!--                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"-->
            <!--                                   :rowsPerPageOptions="[10,20,50]"-->
            <!--                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"-->
            <!--                                   stripedRows responsiveLayout="scroll">-->

            <!--                          <Column field="education_speciality_code" header="Код"></Column>-->
            <!--                          <Column field="education_speciality_name" header="Название ОП"></Column>-->
            <!--                          <Column field="decreeGosoTemplate.decree" header="Название приказа ГОСО"></Column>-->
            <!--                          <Column field="language.native_name" header="Язык обучения"></Column>-->


            <!--                        </DataTable>-->
            <!--            <router-link-->
            <!--                    :to="data.is_selectable?'/selectable-flows?formation_education_program_id='+data.formation_education_program_id+'&education_curriculum_id='+data.education_curriculum_id:'/binding-to-curriculum?discipline_id='+data.discipline_id+'&semester='+data.semester">-->
            <!--                {{data.name}}-->
            <!--            </router-link>-->

        </div>

    </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
    import {getCookie} from "@/utils/helpers/cookies.helpers";

    export default {
        name: "CurriculumDisciplines",

        data() {
            return {
                departmentID: +this.$route.query.department_id || 0,
            }
        },
        computed: {
            ...mapState('educationCurriculum', ['curriculumDisciplinesData']),
            ...mapState('vacancy', ['departments']),
            getDepartmentId() {
                return getCookie('DEPARTMENT_ID') || 6
            }
        },

        methods: {
            ...mapActions('educationCurriculum', ['GET_CURRICULUM_DISCIPLINES_BY_DEPARTMENT']),
            ...mapActions('vacancy', ['GET_DEPARTMENTS']),
            async changeFilterDepartments(e) {
                this.departmentID = e.target.value
                await this.changePage()
            },
            async changePage() {
                this.$router.push(`${this.$route.path}?department_id=${this.departmentID}`)
                await this.GET_CURRICULUM_DISCIPLINES_BY_DEPARTMENT(this.departmentID)
            },

        },
        async mounted() {
            this.departmentID = this.departmentID || this.getDepartmentId
            await this.GET_DEPARTMENTS()
            await this.GET_CURRICULUM_DISCIPLINES_BY_DEPARTMENT(this.departmentID)
            console.log(this.curriculumDisciplinesData.curriculumDisciplines)
        }
    }
</script>

<style scoped>

</style>